<template>
  <el-card class='account-remittance-not-account business-handling my-card' :body-style="cardBody">
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('transferAccount')}}</span></template>
    </el-page-header>
    <div class="form-container">
      <el-divider content-position="left"> <span style="color:red">*</span> {{$t('My account information')}} </el-divider>
      <el-form v-if="transferPeopleInfo" ref="transferPeopleInfo" :model="transferPeopleInfo" label-position="top">
        <el-input type="hidden" size="medium" v-model="transferPeopleInfo.businessCode" readonly></el-input>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="transferPeopleInfo.idCard" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('account')">
              <el-input size="medium" v-model="transferPeopleInfo.cardNo" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('accountAmount')">
              <el-input size="medium" v-model="transferPeopleInfo.accountAmount" readonly>
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 收款人信息 -->
      <el-divider content-position="left" class="update-el-divider">
        <el-form inline ref="queryPayeeInfo" :model="queryPayeeInfo" :rules="rules" style="height:45px;overflow:hidden;">
          <el-form-item prop="param" :label="$t('beneficiaryInfo')" style="margin-bottom:0">
            <el-input size="mini" v-model="queryPayeeInfo.param" :placeholder="$t('inputPhoneOrAccount')"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom:0">
            <el-button size="mini" type="primary" @click="onQuery('queryPayeeInfo','payeeInfo')">{{$t('query')}}{{$t('beneficiaryInfo')}}</el-button>
          </el-form-item>
        </el-form>
      </el-divider>
      <el-form v-if="payeeInfo" ref="payeeInfo" :model="payeeInfo" label-position="top">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :label="$t('firstName')">
              <el-input size="medium" v-model="payeeInfo.firstName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('lastName')">
              <el-input size="medium" v-model="payeeInfo.lastName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('phone')">
              <el-input size="medium" v-model="payeeInfo.phone" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="payeeInfo.idCard" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('account')">
              <el-input size="medium" v-model="payeeInfo.cardNo" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 转账信息 -->
      <el-divider content-position="left" class="update-el-divider">{{$t('transferInfo')}}</el-divider>
      <el-form v-if="payeeInfo&&transferPeopleInfo" ref="transferInfo" :model="transferInfo" :rules="rules" label-position="top">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-form-item :label="$t('transfer amount')" prop="amount">
              <el-input size="medium" v-model="transferInfo.amount" @blur="calculateServiceCharge" :readonly="isCompute">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('serviceCharge')+' '+(isCompute?$t('calculating')+'……':'')" prop="serviceChargeAmount">
              <el-input size="medium" v-model="transferInfo.serviceChargeAmount" readonly :placeholder="$t('AfterEnteringTheAmountItWillBAutomaticallyCalculated')">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('serviceCharge')+$t('payment')" prop="payServiceChargeType">
              <el-select size="medium" v-model="transferInfo.payServiceChargeType">
                <el-option :label="$t('remitterPayment')" :value="0"></el-option>
                <el-option :label="$t('payeePayment')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <transaction-tax-display businessCode="70"></transaction-tax-display>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('messageAuthenticationCode')" prop="smsCode">
              <el-input size="medium" v-model="transferInfo.smsCode">
                <el-button slot="append" @click="getSmsVerification" :disabled="surplusTime>0">
                  <span v-if="!surplusTime">{{$t('Send SMS verification code')}}</span>
                  <span v-else>{{$t("send it again")+`(${surplusTime})`}}</span>
                </el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item :label="$t('remark')">
              <el-input size="medium" type="textarea" v-model="transferInfo.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bottom-button" v-if="transferPeopleInfo&&payeeInfo&&transferPeopleInfo">
      <el-button v-if="!printParam" size="medium" :loading="isLoading" type="primary" @click="onSubmit('transferInfo')">{{$t('submit')}}</el-button>
    </div>
  </el-card>
</template>

<script>
// import printCredentials from '@/components/printCredentials'
import customer from "@/api/customer"
import customerBusiness from '@/api/customerBusiness'
import TransactionTaxDisplay from '@/components/TransactionTaxDisplay'

export default {
  components: {  /**printCredentials,*/  TransactionTaxDisplay },
  data () {
    return {
      isLoading: false,

      /**查询汇款人信息 */
      queryForm: {},
      /**转账人信息 */
      transferPeopleInfo: null,

      /**收款人账户 */
      queryPayeeInfo: {},
      /**收款人信息 */
      payeeInfo: null,
      isCompute: false,
      surplusTime: 0,
      //转账信息
      transferInfo: {},

      //转账成功后的弹框
      printParam: null,
    }
  },
  computed: {
    cascader () { return this.$store.state.format.cascader },
    cardBody () { return this.$store.state.style.cardBody },
    countryTree () { return this.$store.state.globalParameter.countryTree; },
    rules () {
      return {
        param: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payServiceChargeType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        country_region_city: [{ type: "array", required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        smsCode: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  methods: {
    calculateServiceCharge () {
      let _this = this;
      if (this.transferInfo.amount > this.transferPeopleInfo.accountAmount) {
        this.$alert(this.$t('The amount transferred must not be greater than the account balance'), this.$t('warning'), {
          callback: () => {
            _this.$set(_this.transferInfo, "amount", this.transferPeopleInfo.accountAmount);
          }
        });
        return;
      }
      if (!_this.transferInfo.amount) {
        _this.$set(_this.transferInfo, "serviceChargeAmount", undefined)
        return;
      }
      _this.isCompute = true;
      customerBusiness.serviceChargeCompute({
        param: { amount: _this.transferInfo.amount, businessCode: _this.$route.params.code },
        success: res => {
          setTimeout(() => {
            if (res.code != 200) _this.$message.warning(_this.$t(res.msg));
            _this.$set(_this.transferInfo, "serviceChargeAmount", res.data || 0);
            _this.isCompute = false;
          }, 500);
        }
      })
    },
    /**查询转款人和收款人信息 */
    onQuery (refName, rank) {
      let _this = this;
      function test (p) {
        customer.getByAccountOrPhone({
          param: p,
          success: res => {
            if (res.code != 200) {
              _this.$message.warning(_this.$t(res.msg))
              return;
            }
            _this[rank] = {
              businessCode: _this.$route.params.code,
              firstName: res.data.name,
              lastName: res.data.surname,
              phone: res.data.phoneNo,
              idCard: res.data.idCard,
              accountAmount: res.data.accountAmount,
              cardNo: res.data.cardNo,
              cardId: res.data.cardId
            };
          }
        })
      }
      if (rank === "transferPeopleInfo") {
        let cardInfo = this.$store.state.globalParameter.cardInfo
        test({ cardNo: cardInfo.cardNo })
      }
      else {
        this.$refs[refName].validate(valid => {
          if (valid) {
            let p = {};
            if (_this[refName].param.length === 15) p.cardNo = _this[refName].param;
            else p.phone = _this[refName].param;
            test(p)
          }
          else return false;
        })
      }
    },

    getSmsVerification () {
      let _this = this;
      customerBusiness.sendVerificationCode({
        param: { phone: this.transferPeopleInfo.phone }, success: function (res) {
          if (res.code != 200) {
            _this.$message.warning(_this.$t(res.msg))
            return
          }

          _this.surplusTime = 60;
          let interval = setInterval(() => {
            _this.surplusTime -= 1;
            if (_this.surplusTime === 0)
              clearInterval(interval);
          }, 1000)

          _this.$message.success(_this.$t("SMS verification code sent successfully") + "，" + _this.$t("Verification code Receiving number") + _this.transferPeopleInfo.phone);
        }
      })
    },
    onSubmit (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        _this.isLoading = true;
        let p = {
          businessCode: _this.transferPeopleInfo.businessCode,
          //转账人信息
          remittorCardId: _this.transferPeopleInfo.cardId,
          remittorCardNo: _this.transferPeopleInfo.cardNo,

          //收款人信息
          payeeCardId: _this.payeeInfo.cardId,
          payeeCardNo: _this.payeeInfo.cardNo,

          //转账信息
          ..._this[refName]
        }

        customerBusiness.transferAccounts({
          param: p,
          success: res => {
            if (res.code == 200) {
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.$set(_this, "printParam", res.data)
            }
            else {
              _this.$message.warning(_this.$t(res.msg));
            }
            _this.isLoading = false;
          }
        })
      })
    }
  },
  mounted () {
    this.queryForm = { param: this.loginInfo };
    this.onQuery("queryForm", "transferPeopleInfo")
  }
}
</script>

<style lang="less" scoped>
.account-remittance-not-account.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .form-container {
      position: absolute;
      top: 60px;
      bottom: 80px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
    }
  }
  .el-form {
    height: auto;
  }
  .update-el-divider {
    margin-top: 40px;
  }
}
</style>